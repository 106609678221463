.splide__pagination__page.is-active {
  background: #E0D6C5;
}

.splide__pagination__page {
  background: #F0E8DD;
}

.splide__slide {
  border-radius: 4px;
}
